#cy {
	border: 1px solid #cccccc;
	height: 600px;
	width: 100%;
	margin: 0px auto;

	background: url("../images/pattern_swirl.webp") 0px 0px;
}

.modal-box-container {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1010; /* Sit on top */
	padding-top: 48px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4);

	&.show {
		display: block;
	}
}

.modal-box-wrapper {
	position: relative;
	background-color: #fefefe;
	margin: auto;
	// padding: 20px;
	width: 80%;

	@media only screen and (max-width: 750px) {
		padding: 10px 30px;
		box-sizing: border-box;
		text-align: center;
	}
}

.modal-box-wrapper {
	.close {
		position: absolute;
		top: 0px;
		right: 0px;
		font-size: 20px;
		font-weight: bold;
		z-index: 99;
	}

	.close:hover,
	.close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}

	.downlaod-img-wrapper {
		border: 1px solid #ccc;
		overflow: scroll;
	}
}

.block-tree {
	position: relative;
	margin-top: 80px;

	@media only screen and (max-width: 750px) {
		margin-top: 100px;
	}
}
#btn-download {
	cursor: pointer;
	position: absolute;
	top: -42px;
	right: 12px;
}
#btn-share {
	cursor: pointer;
	position: absolute;
	top: -45px;
	right: 45px;
	display: flex;
	flex-direction: row;
	gap: 6px;
}

.tree-header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
	margin: 50px 0px;

	h1 {
		margin: 0px;
		padding: 10px 0px 0px 0px;
	}

	img {
		width: 140px;
	}

	@media only screen and (max-width: 750px) {
		margin-bottom: 120px;

		h1 {
			padding-top: 24px;
		}

		img {
			width: 80px;
		}
	}
}

#btn-change-lang {
	position: absolute;
	top: -40px;
	left: 10px;
	display: flex;
	flex-direction: row-reverse;
	gap: 12px;
	justify-content: start;

	p {
		margin: 0px;
		margin-top: 2px;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 48px;
		height: 23px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 18px;
		width: 19px;
		left: 4px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: #2196f3;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196f3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(22px);
		-ms-transform: translateX(22px);
		transform: translateX(22px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
}

#modal-box-person-selector {
	text-align: center;

	.modal-box-wrapper {
		// padding-top: 50px;

		h2 {
			text-transform: capitalize;
		}
	}
}

.block-legends {
	h3 {
		border-bottom: 2px solid black;
		padding-bottom: 14px;
	}

	.legends-wrapper {
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 20px;

		.legend {
			span {
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-right: 5px;
				border-radius: 50%;
				background-color: red;
			}

			p {
				display: block;
				float: right;
				margin: 2px;
			}

			&.legend-current {
				span {
					background-color: #6558f5;
				}
			}

			&.legend-male {
				span {
					background-color: #b6b3db;
				}
			}

			&.legend-female {
				span {
					background-color: #1aae9f;
				}
			}

			&.legend-wife {
				span {
					background-color: #ff59d6;
				}
			}
		}
	}
}

#search-person-box {
	position: absolute;
	top: -90px;
	left: 14px;

	button {
		background: none;
		color: black;
		border: 1px solid black;
		border-radius: 8px;
		&:hover {
			background-color: black;
			color: white;
		}
	}
}

.add-child-header {
	font-weight: bold;
	font-size: 22px;
	margin: 0px 0px 10px 0px;
}

.person-info {
	display: flex;
	flex-direction: row;
	min-height: 400px;

	h2 {
		margin-top: 0px;
	}

	.side {
		flex: 1;
		width: 50%;
	}

	.left-side {
		position: relative;
		background-color: #3887f4;
		color: white;
		text-align: left;
		padding: 20px;
	}

	.right-side {
		position: relative;

		input {
			padding: 5px 4px;
		}

		.add-child-form {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 6px;
			padding: 20px;
			text-align: left;
			width: 100%;

			p {
				margin: 0px;
				padding: 10px 0px;
			}

			.form-checkbox {
				margin-top: 10px;
			}

			.form-item-gender {
				margin-top: 9px;
			}

			@media only screen and (max-width: 750px) {
				grid-template-columns: 1fr;
			}
		}

		#not-allowed {
			margin-top: 160px;
		}

		.btn-add-child {
			margin-top: 50px;
			width: 135px;
			background-color: #3887f4;
		}
	}

	.btn {
		cursor: pointer;
		position: absolute;
		top: 15px;
		right: 15px;

		&.btn-delete {
			position: relative;
			top: 0px;
			right: 0px;
			display: inline-block;
			background-color: #ffffff;
			color: #3887f4;
			margin-top: 20px;
		}
	}

	.person-info-box {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.person-info-box p {
		margin: 0px;
		padding: 8px 0px;
	}

	@media only screen and (max-width: 750px) {
		flex-direction: column;

		h2 {
			margin-top: 30px;
		}

		.left-side {
			display: none;
			width: 100%;
			box-sizing: border-box;
		}
		.person-info-box {
			display: flex;
			flex-direction: column;
		}
		.right-side {
			// display: none;
			width: 100%;
			box-sizing: border-box;
		}
	}
}

.history-wrapper {
	width: 100%;
	text-align: left;
	padding-left: 20px;

	.content {
		height: 500px;
		margin: 20px 0px;
		overflow: scroll;

		.history-item {
			cursor: pointer;

			&.even {
				background-color: #e1eeff;
			}
			p {
				text-transform: capitalize;
				margin: 0px;
				padding: 10px 5px;
			}
		}
	}
}
