.loader {
    width: 48px;
    height: 48px;
    border: 10px solid #39b54b;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    position: absolute;
    box-sizing: border-box;
    left: 3px;
    top: 20px;
    border: 15px solid transparent;
    border-right-color: #39b54b;
    transform: rotate(-40deg);
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.loader-container {
  width: 50px;
  margin: 100px auto;
}